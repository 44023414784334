import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import helper from "../../services/helper.service";
import adminApi from "../../services/admin.service";

function AdminGPSTracker() {
  const [gpsTrackers, setGPSTrackers] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [newGPSTracker, setNewGPSTracker] = useState({ name: "", tracking_id: "", club_id: "" });
  const [isEdit, setIsEdit] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchGPSTrackers();
    fetchClubs();
  }, []);

  const fetchGPSTrackers = () => {
    adminApi.getGPSTrackers().then((response) => {
      if (response.status === 200) {
        setGPSTrackers(response.data);
      } else {
        helper.showToast("Error", "Failed to fetch GPS Trackers.", 3);
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(fetchGPSTrackers, 60000);
    return () => { clearInterval(interval)}
  }, []);

  const fetchClubs = () => {
    adminApi.getClubList().then((response) => {
      if (response.status === 200) {
        setClubs(response.data.sort((a, b) => a.name.localeCompare(b.name)));
      }
    });
  };
  const handleInputChange = (index, field, value) => {
    setGPSTrackers((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const handleNewGPSTrackerChange = (field, value) => {
    setNewGPSTracker((prev) => ({ ...prev, [field]: value }));
  };

  const onEdit = (index) => {
    setActiveIndex(index);
    setIsEdit(true);
  };

  const onSave = () => {
    const gpsTracker = gpsTrackers[activeIndex];
    adminApi.updateGPSTracker(gpsTracker).then((response) => {
      if (response.status === 200) {
        helper.showToast("Success", "GPS Tracker updated successfully.", 1);
        setIsEdit(false);
        fetchGPSTrackers();
      } else {
        helper.showToast("Error", "Failed to update GPS Tracker.", 3);
      }
    });
  };

  const onCreate = () => {
    adminApi.createGPSTracker(newGPSTracker).then((response) => {
      if (response.status === 200) {
        helper.showToast("Success", "GPS Tracker created successfully.", 1);
        setNewGPSTracker({ name: "", tracking_id: "", club_id: "" });
        setIsModalOpen(false);
        fetchGPSTrackers();
      } else {
        helper.showToast("Error", "Failed to create GPS Tracker.", 3);
      }
    });
  };

  return (
    <>
      <AddClubHeader />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
                <h3 className="text-white mb-0">GPS Trackers Management</h3>
                <Button color="primary" onClick={() => setIsModalOpen(true)}>Create GPSTracker</Button>
              </CardHeader>
              <Table className="align-items-center table-dark table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Tracking ID</th>
                    <th>Battery Level</th>
                    <th>Club</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {gpsTrackers.map((gpsTracker, index) => (
                    isEdit && activeIndex === index ? (
                      <tr key={index}>
                        <td>
                          <Input value={gpsTracker.name} onChange={(e) => handleInputChange(index, "name", e.target.value)} />
                        </td>
                        <td>
                          <Input value={gpsTracker.tracking_id} onChange={(e) => handleInputChange(index, "tracking_id", e.target.value)} />
                        </td>
                        <td>{gpsTracker.battery_level}</td>
                        <td>
                          <Input
                            type="select"
                            value={gpsTracker.club_id || ""}
                            onChange={(e) => handleInputChange(index, "club_id", e.target.value)}
                          >
                            <option value="">Unattached</option>
                            {clubs.map((club) => (
                              <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                          </Input>
                        </td>
                        <td>
                          <Button color="success" onClick={onSave}>Save</Button>
                          <Button color="warning" onClick={() => setIsEdit(false)}>Cancel</Button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td>{gpsTracker.name}</td>
                        <td>{gpsTracker.tracking_id}</td>
                        <td>{gpsTracker.battery_level}</td>
                        <td>{clubs.find(club => club.id === gpsTracker.club_id)?.name || "Unattached"}</td>
                        <td>
                          <Button color="info" onClick={() => onEdit(index)}>Edit</Button>
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Create New GPS Tracker</ModalHeader>
        <ModalBody>
          <Form>
            <Input placeholder="Name" value={newGPSTracker.name} onChange={(e) => handleNewGPSTrackerChange("name", e.target.value)} />
            <Input placeholder="Tracking ID" value={newGPSTracker.tracking_id} onChange={(e) => handleNewGPSTrackerChange("tracking_id", e.target.value)} />
            <Input type="select" value={newGPSTracker.club_id} onChange={(e) => handleNewGPSTrackerChange("club_id", e.target.value)}>
              <option value="">Unattached</option>
              {clubs.map((club) => (
                <option key={club.id} value={club.id}>{club.name}</option>
              ))}
            </Input>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCreate}>Create</Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AdminGPSTracker;

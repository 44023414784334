import http from "./http.service";
import session from "./session.service";

const path = {
    login: 'admin/loginClub',
    addClub: 'admin/addClub',
    getClubList: 'admin/getClubList',
    removeClub: 'admin/removeClub',
    updateClub: 'admin/updateClub',
    getBoxList: 'admin/getBoxList',
    updateBox: 'admin/updateBox',
    getAllGolferList: 'admin/getAllGolferList',
    getAllPrevGolferList: 'admin/getAllPrevGolferList',
    updateBoxWithCSV: 'admin/updateBoxWithCSV',
    updateGroup: 'admin/updateGroup',
    getMembers: 'customer/getMembers',
    updateMember: 'customer/updateMember',
    uploadMembers: 'admin/uploadMembers',
    getAirTags: 'admin/getAirtags',
    createAirTag: 'admin/createAirtag',
    updateAirTag: 'admin/updateAirtag',
    getGpsTrackers: 'admin/getGpsTrackers',
    createGpsTracker: 'admin/createGpsTracker',
    updateGpsTracker: 'admin/updateGpsTracker',
}

export default {
    login: function(credential) {
        return http.post(path.login, credential, {});
    },
    
    addClub: function(body) {
        return http.postClub(path.addClub, body, {});
    },
    getGPSTrackers: function(body){
        return http.post(path.getGpsTrackers, body, {});
    },
    createGPSTracker: function(body){
        return http.post(path.createGpsTracker, body, {});
    },
    updateGPSTracker: function(body){
        return http.post(path.updateGpsTracker, body, {});
    },
    getAirTags: function(body){
        return http.post(path.getAirTags, body, {});
    },
    createAirTag: function(body){
        return http.post(path.createAirTag, body, {});
    },
    updateAirTag: function(body){
        return http.post(path.updateAirTag, body, {});
    },
    getMembers: function(body){
        return http.post(path.getMembers, body, {});
    },
    updateMember: function(body){
        return http.post(path.updateMember, body, {});
    },
    uploadMembers: function(body){
        return http.post(path.uploadMembers, body, {});
    },    
    getClubList: function(body){
        return http.post(path.getClubList, body, {});
    },
    deleteClub: function(body){
        return http.post(path.removeClub, body, {});
    },
    updateClub: function(body){
        return http.updateClub(path.updateClub, body, {});
    },
    updateGroup: function(body){
        return http.updateGroup(path.updateGroup, body, {});
    },
    getBoxList: function(body){
        return http.post(path.getBoxList, body, {});
    },
    updateBox: function(body){
        return http.post(path.updateBox, body, {});
    },
    getAllGolferList: function(body){
        return http.post(path.getAllGolferList, body, {});
    },
    getAllPrevGolferList: function(body){
        return http.post(path.getAllPrevGolferList, body, {});
    },
    updateBoxWithCSV: function(body){
        return http.post(path.updateBoxWithCSV, body, {});
    }
}
import React from 'react';
import './Marker.css';

const CustomMarker = (props) => {
    const { game } = props;
    const { members, caddies, last_hole_played: lastHole, pace_formatted: currentPace} = game;
    const color = currentPace[0] == '(' ? 'red' : 'green';
    const [showInfo, setShowInfo] = React.useState(false);
    return (
      <div className="marker"
        style={{ backgroundColor: 'white', cursor: 'pointer'}}
        onMouseOver ={() => setShowInfo(true)} 
        onMouseOut ={() => setShowInfo(false)} 
        // title={name}
      >
        <div style={{color: color, paddingLeft: 4, paddingTop: 4, fontSize: 14, textTransform: "uppercase"}}>
            {caddies[0] ? caddies[0].first_name[0]+caddies[0].last_name[0] : (members[0]?.first_name[0] || 'N') + (members[0]?.last_name[0] || 'N')}
        </div>
        {
            showInfo ? 

            <div style={{height: "200px", width: "250px", fontSize: 16, marginTop: -230, backgroundColor: "white", justifyContent: "center", alignContent: "center", alignItems: "center", padding: 10, borderColor: "green", borderWidth: 10, borderRadius: "10px"}}>
                <div style={{marginTop: 4, color: "black"}}>
                    Golfers: 
                    <div style={{fontSize: 14}}>
                        <ol>
                            {members.map((m) => <li>{`${m.first_name} ${m.last_name}`}</li>)}
                        </ol>
                    </div>
                </div>
                {
                    caddies.length > 0 &&
                    <div style={{marginTop: 4, color: "black"}}>
                        Caddies: 
                        <div style={{fontSize: 14}}>
                            <ol> 
                                {caddies.map((c) => <li>{`${c.first_name} ${c.last_name}`}</li>)}
                            </ol>
                        </div>
                    </div>
                }
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                    Last Hole Played: <span style={{fontSize: 14}}>{lastHole}</span>
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                    Current Pace: <span style={{ color: color, fontSize: 14}}>{currentPace}</span>
                </div>
            
            </div>
            :
            <></>
        }
        
        </div>
    );
  };

  export default CustomMarker;
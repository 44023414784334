import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import AddClubHeader from "../../components/Headers/AddClubHeader.js";
import helper from '../../services/helper.service';
import customerClient from '../../services/customer.service';
import sessionService from '../../services/session.service';

function UpdateClubProperties() {
  const history = useHistory();
  const club = sessionService.get('clubInfo');
  console.log("club ", club)
  const [clubProperties, setClubProperties] = useState({
    club_id: club.clubId,
    tee_start: club.tee_start || "8:00",
    tee_end: club.tee_end || "14:00",
    tee_duration: club.tee_duration || 10,
  });

  useEffect(() => {
    console.log("clubProperties", clubProperties);

  }, [clubProperties])

  const handleTimeChange = (event, field) => {
    setClubProperties({ ...clubProperties, [field]: event.target.value });
  };

  const handleDurationChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setClubProperties({ ...clubProperties, tee_duration: value });
    }
  };

  const handleSubmit = async () => {
    try {
      const formattedData = {
        ...clubProperties,
        tee_start: convertTo24HourFormat(clubProperties.tee_start),
        tee_end: convertTo24HourFormat(clubProperties.tee_end),
      };
      
      const response = await customerClient.updateClub(formattedData);
      if (response.status === 200) {
        const data = response.data;
        sessionService.set('clubInfo', {...club, tee_start: data.tee_start, tee_end: data.tee_end, tee_duration: data.tee_duration});
        helper.showToast("Success", "Tee time updated successfully.", 1);
      } else {
        helper.showToast("Error", "Failed to update tee time.", 3);
      }
    } catch (error) {
      console.error("Error updating tee time: ", error);
      helper.showToast("Error", "Invalid club details", 3);
    }
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <AddClubHeader />
      <Container className="mt--9" fluid>
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Update Tee Time</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Club Timing</h6>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Tee Start</label>
                        <Input
                          type="select"
                          value={clubProperties.tee_start}
                          onChange={(e) => handleTimeChange(e, "tee_start")}
                        >
                          {generateTimeOptions()}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Tee End</label>
                        <Input
                          type="select"
                          value={clubProperties.tee_end}
                          onChange={(e) => handleTimeChange(e, "tee_end")}
                        >
                          {generateTimeOptions()}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Duration (minutes)</label>
                        <Input
                          type="number"
                          value={clubProperties.tee_duration}
                          min="1"
                          onChange={handleDurationChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Button color="primary" onClick={handleSubmit}>
                        Update Properties
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 5; hour <= 11; hour++) {
    let dhour = hour.toString().padStart(2, '0');
    for (let minute of ["00", "30"]) {
      times.push({ value: `${dhour}:${minute}`, label: `${hour}:${minute} AM`});
    }
  }
  const hour = 12;
  let dhour = (hour + 12).toString().padStart(2, '0');
  for (let minute of ["00", "30"]) {
    times.push({ value: `${dhour}:${minute}`, label: `${hour}:${minute} PM`});
  }

  for (let hour = 1; hour <= 10; hour++) {
    let dhour = (hour + 12).toString().padStart(2, '0');
    for (let minute of ["00", "30"]) {
      if(hour === 10 && minute==="30") {
        continue;
      }
      times.push({ value: `${dhour}:${minute}`, label: `${hour}:${minute} PM`});
    }
  }

  return times.map((time, index) => (
    <option key={index} value={time.value}>{time.label}</option>
  ));
};

export default UpdateClubProperties;

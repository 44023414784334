import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from "reactstrap";
import customerApi from '../../services/customer.service';
import helper from '../../services/helper.service';
import session from '../../services/session.service';
import AddClubHeader from '../../components/Headers/AddClubHeader';
import './gameList.scss';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import GoogleMapReact from 'google-map-react';
import CustomMarker from '../../components/Markers/CustomMarker';

const getLocalDate = (days = 0) => {
  const date = new Date(Date.now() + days*86400000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return  `${year}-${month}-${day}`;
}

function GameList(props) {
  const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
  const [games, setGames] = useState([]);
  const [members, setMembers] = useState([]);
  const [boxTimeList, setBoxTimeList] = useState([]);
  const [caddies, setCaddies] = useState([]);
  const [airtags, setAirtags] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [gpsTrackers, setGpsTrackers] = useState([]);
  const [centerLati, setCenterLati] = useState(session.get("clubInfo").latitude);
  const [centerLong, setCenterLong] = useState(session.get("clubInfo").longitude);
  const newGameTemplate = {
    type: '18',
    status: 'created',
    tracker_type: 'gps',
    start_box: 1,
    tracker_id: {},
    tee_time: '10:00',
    date: getLocalDate(),
    selectedMembers: [],
    selectedCaddies: [],
    club_id: clubId
  };
  const [selectedGame, setSelectedGame] = useState({...newGameTemplate});
  const [teeTimes, setTeeTimes] = useState([]);
  const [gameResponse, setGameResponse] = useState([]);
  const [club, setClub] = useState(session.get('clubInfo'));
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(getLocalDate());
  const statusText = {
    created: 'Created',
    'in-progress': 'In Progress',
    completed: 'Completed'
  };

  const dateOptions = [
    { label: 'Yesterday', value: getLocalDate(-1) },
    { label: 'Today', value: getLocalDate() },
    { label: 'Tomorrow', value: getLocalDate(1) },
    { label: 'Next Day', value: getLocalDate(2) },
    { label: 'History', value: `history:${getLocalDate()}` }
  ];
  const holeToBoxMappings = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 22,
    11: 12,
    12: 13,
    13: 14,
    14: 15,
    15: 16,
    16: 17,
    17: 18,
    18: 19,
  };

  const boxToHoleMappings = {
     1:  1,
     2:  2,
     3:  3,
     4:  4,
     5:  5,
     6:  6,
     7:  7,
     8:  8,
     9:  9,
     11: 10,
     22: 10,
     12: 11,
     13: 12,
     14: 13,
     15: 14,
     16: 15,
     17: 16,
     18: 17,
     19: 18,
     23: 1
  };

  useEffect(() => {
    if(clubId == 1){
			const { state } = props.location;
			console.log("club: ", state.club);
			const club = state.club;
			setClubId(club.clubId);
      setClub(club);
      setCenterLati(club.latitude);
      setCenterLong(club.longitude);
      console.log('sdzsdfd', club);
			global.isAdmin = true;
		}
  }, [clubId]);

  useEffect(() => {
    console.log(boxTimeList.length +"  bbbb "+gameResponse.length)
    if(boxTimeList.length && gameResponse.length) {
      const gamesMap = gameResponse?.map((game) => {
        const {box_entries_sorted: box_entries} = game;
        let cumulative = 0;
        if(box_entries.length) {
          if(box_entries.length >=2) {
            game.last_hole_played = boxToHoleMappings[box_entries[box_entries.length - 2]?.box_number];
            game.time_through_last_hole = box_entries[box_entries.length - 1]?.entry_time?.split(" ")[1];
          }
          game.total_holes_played = box_entries.length - 1;
          let actualTotal = 0;
          let standardTotal = 0;
          box_entries.forEach((boxEntry, boxIndex) => {
            const standard = boxTimeList.find((bt) => (bt.box_number == boxEntry.box_number) || (bt.box_number == 11 && boxEntry.box_number == 22) || (bt.box_number == 1 && boxEntry.box_number == 23))?.box_time;
            boxEntry.standard = standard;
            standardTotal += standard;
            if(boxEntry.entry_time && boxEntry.exit_time) {
              boxEntry.timeTaken = getMinutesDifference(boxEntry.entry_time, boxEntry.exit_time);
              if(boxIndex == 0 && [1,22].includes(boxEntry.box_number)) {
                boxEntry.timeTaken += 3;
              }
              actualTotal += boxEntry.timeTaken;
              if(standard !== undefined) {
                const difference = standard - boxEntry.timeTaken;
                cumulative += difference;
                boxEntry.variance = difference;
                boxEntry.cumulative =  cumulative;
                boxEntry.variance = boxEntry.variance < 0 ? `(${boxEntry.variance * -1})` : `${boxEntry.variance}`
                boxEntry.cumulative = boxEntry.cumulative < 0 ? `(${boxEntry.cumulative * -1})` : `${boxEntry.cumulative}`
              }
            }
          })
          game.actualTotal = actualTotal;
          game.standardTotal = standardTotal;
        }
        if(game.time_through_last_hole) {
          game.time_through_last_hole = strTo12Hours(game.time_through_last_hole);
        }
        game.start_hole = boxToHoleMappings[game.start_box];
        game.pace = cumulative;
        if(game.last_lat_long) {
          game.last_lat_long = JSON.parse(game.last_lat_long);
        }
        if(selectedDate !== `history:${getLocalDate()}` && game.actual_time) {
          game.actual_time = strTo12Hours(game.actual_time?.split(" ")[1]);
        }
        game.pace_formatted = game.pace < 0 ? `(${game.pace * -1 })` : `${game.pace}`;
        return game;
      }) || [];
      setGames(gamesMap);
    }
  }, [gameResponse, boxTimeList]);

  useEffect(() => {
    fetchGames();
    const interval = setInterval(() => {
      fetchGames();
    }, 10000);
    return () => {
      clearInterval(interval);
    }
  }, [selectedDate, clubId]);

  useEffect(() => {
    const interval = setInterval(fetchGpsTrackers, 60000);
    return () => { clearInterval(interval)}
  }, []);

  useEffect(() => {
    fetchBoxTimeList();
    fetchMembers();
    fetchCaddies();
    fetchAirtags();
    fetchGpsTrackers();
    generateTeeTimeOptions();
  }, [clubId]);

  const fetchBoxTimeList = async () => {
    const response = await customerApi.getBoxTimeList({ club_id: clubId });
    if (response.status === 200) {
      setBoxTimeList(response.data);
    }
  }

  const fetchGames = async () => {
    const response = await customerApi.getGames({ club_id: clubId, date: selectedDate });
    if (response.status === 200) {
      setGameResponse(response.data);
    }
  };

  const fetchMembers = async () => {
    const response = await customerApi.getMembers({ club_id: clubId });
    if (response.status === 200) {
      setMembers(response.data);
    }
  };

  const fetchCaddies = async () => {
    const response = await customerApi.getCaddies({ club_id: clubId });
    if (response.status === 200) {
      setCaddies(response.data);
    }
  };

  const fetchAirtags = async () => {
    const response = await customerApi.getAirtags({ club_id: clubId });
    if (response.status === 200) {
      setAirtags(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const fetchGpsTrackers = async () => {
    const response = await customerApi.getGpsTrackers({ club_id: clubId });
    if (response.status === 200) {
      setGpsTrackers(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  function getNextClosestTime() {
    const now = new Date();
    const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes
    const gameTeeTimes = games.map(game => game.tee_time);
    const timeList = teeTimes.map(t => t.value).filter(v => !gameTeeTimes.includes(v)); 
    // Convert list times to minutes and sort them
    const sortedTimes = timeList
        .map(time => {
            const [hours, minutes] = time.split(':').map(Number);
            return { time, totalMinutes: hours * 60 + minutes };
        })
        .sort((a, b) => a.totalMinutes - b.totalMinutes);

    // Find the next closest time
    for (let entry of sortedTimes) {
        if (entry.totalMinutes > currentTime) {
            return entry.time; // Return the first time greater than the current time
        }
    }

    // If no future time exists, return the first time of the next day (loop around)
    return sortedTimes.length > 0 ? sortedTimes[0].time : null;
  }

  const createOrUpdateGame = async () => {
    if(selectedGame.tee_time === 'custom') {
      selectedGame.tee_time = selectedGame.customTeeTime;
    }
    selectedGame.selectedMembers = selectedGame.selectedMembers.map(m => m.value);
    selectedGame.selectedCaddies = selectedGame.selectedCaddies.map(c => c.value);
    selectedGame.tracker_id = selectedGame.tracker_id?.value;
    const response = editMode ? await customerApi.updateGame(selectedGame) : await customerApi.createGame(selectedGame);
    if (response.status === 200) {
      helper.showToast('Success', `Game ${editMode ? 'updated' : 'created'} successfully.`, 1);
      fetchGames();
      setModal(false);
    } else {
      helper.showToast('Error', `Failed to ${editMode ? 'update' : 'create'} game.`, 3);
    }
  };

  function timeToMinutes(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  }
  
  const generateTeeTimeOptions = () => {
    const options = [{value: 'custom', label: 'Add Custom'}];
    const {tee_start, tee_end, tee_duration} = club;
    if(tee_start && tee_end && tee_duration) {
      const start = timeToMinutes(tee_start);
      const end = timeToMinutes(tee_end);
      const duration =  tee_duration;
      let currentTime = start;

      while (currentTime <= end) {
        const hours = Math.floor(currentTime / 60);
        const minutes = currentTime % 60;
        const formatted24Hour = format24Hours(hours, minutes);
        const formatted12Hour = format12Hours(hours, minutes);
        options.push({ value: formatted24Hour, label: formatted12Hour });
        currentTime += duration;
      }
      setTeeTimes(options);
    }
  };
  const deleteGame = async (gameId) => {
    if (!window.confirm("Are you sure you want to delete this game?")) return;
    
    const response = await customerApi.deleteGame({ id: gameId });
  
    if (response.status === 200) {
      helper.showToast('Success', 'Game deleted successfully.', 1);
      fetchGames(); // Refresh the game list
    } else {
      helper.showToast('Error', 'Failed to delete game.', 3);
    }
  };
  const strTo12Hours = (str) => {
    const [hours, minutes] = str.split(':').map(Number);
    return format12Hours(hours, minutes);
  }
  const format24Hours = (hours, minutes) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  const format12Hours = (hours, minutes) => {
    return `${(hours % 12 || 12)}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
  }

  const openEditModal = (game) => {
    const option = teeTimes.find((option) => option.value === game.tee_time);
    const tracker = (game.tracker_type === 'airtag' ? airtags.find(device => device.tracking_id === game.tracker_id) : gpsTrackers.find(device => device.tracking_id === game.tracker_id));
    setSelectedGame({
      ...game,
      selectedMembers: game.members.map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name} (${member.email} - ${member.phone})`
      })),
      selectedCaddies: game.caddies.map(caddie => ({
        value: caddie.id,
        label: `${caddie.first_name} ${caddie.last_name} (${caddie.email} - ${caddie.phone})`
      })),
      tracker_id: getTrackerOption(tracker, game.tracker_type),
      tee_time: !option ? 'custom': game.tee_time ,
      customTeeTime: game.tee_time,
    });

    setEditMode(true);
    setModal(true);
  };

  const openDetailModal = (game) => {
    setDetailModal(true);
    setSelectedGame(game);
  }

  const getMinutesDifference = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    
    // Get the difference in milliseconds
    const diffInMs = Math.abs(date2 - date1);
    
    // Convert to minutes
    return Math.floor(diffInMs / (1000 * 60));
  };

  const openCreateModal = () => {
    setModal(true); 
    setEditMode(false);
    const nextTee = getNextClosestTime() || '10:00';
    setSelectedGame({...newGameTemplate, tee_time: nextTee });
  }

  const getBatteryColor = (level) => {
    if (level <= 30) return { backgroundColor: "#f5365c" };
    if (level <= 60) return { backgroundColor: "#fb6340" };
    return { backgroundColor: "#2dce89" };
  };

  const getTrackerLabel = (tracker, type) => {
    return type === "airtag" ? `airtag: ${tracker.name}` : `gps: ${tracker.name} - ${tracker.battery_level}%`;
  }

  const getTrackerOption = (tracker, type) => {
    console.log(tracker);
    const result = {value: tracker.id, label: getTrackerLabel(tracker, type)};
    if(type === "gps") {
      result.color = getBatteryColor(tracker?.battery_level);
    } else {
      result.color = {backgroundColor: 'grey'};
    }
    console.log(result)
    return result;
  }
  const getMapOptions = (maps) => {
    return {
        // disableDefaultUI: true,
        // mapTypeControl: true,
        // streetViewControl: true,
        mapTypeId: maps.MapTypeId.SATELLITE,
        // heading: -180,
        rotateControl: true,
    };
  };

  return (
    <>
      
      {
        showMap ? <>
        <CustomerHeader />
        {/* Page content */}
        <Container className="mt--7" fluid >
          {/* <Row> */}
          <div style={{ height: '100vh', width: '100%', position: 'relative'  }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCk2rpWi4waVXWK-zBmstyi7JJOlHinwI8' }}
                yesIWantToUseGoogleMapApiInternals
                defaultCenter={{
                    lat: centerLati,
                    lng: centerLong
                }}
                defaultZoom={16}
                options={getMapOptions}
            >
              {
                games.filter(game => game.last_lat_long).map((game) => 
                  <CustomMarker
                    lat={game.last_lat_long[0]}
                    lng={game.last_lat_long[1]}
                    game={game}
                  />
                )
              }
            </GoogleMapReact>
            <Button 
              color="danger" 
              style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
              onClick={() => setShowMap(false)}
            >
              Close Map
            </Button>
          </div>
        </Container>
      </> 
      :
      <>
        <AddClubHeader/>
        <Container className="mt-4" fluid>
          <Row>
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="bg-white border-0 d-flex justify-content-between">
                  <h3 className="mb-0">Games</h3>
                  <div>
                    {dateOptions.map((option) => (
                      <Button
                        key={option.value}
                        color={selectedDate === option.value ? 'primary' : 'secondary'}
                        onClick={() => setSelectedDate(option.value)}
                        className="mr-2"
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                  <Button color="primary" onClick={() => setShowMap(true)}>
                    View Map
                  </Button>
                  <Button color="primary" onClick={openCreateModal}>Create Game</Button>
                </CardHeader>
                <CardBody>
                  <Table responsive style={{}}>
                    <thead>
                      <tr>
                        <th className='multi-line-header'> {global.isAdmin ? 'ID' : '#'}</th>
                        <th className='multi-line-header'>Type</th>
                        <th className='multi-line-header'>Members</th>
                        <th className='multi-line-header'>Caddies</th>
                        <th className='multi-line-header'>Tee Time</th>
                        <th className='multi-line-header'>Start Time</th>
                        <th className='multi-line-header'>Start Hole</th>
                        <th className='multi-line-header'>Last Hole Played</th>
                        <th className='multi-line-header'># of Holes Played</th>
                        <th className='multi-line-header'>Time Through Last Hole</th>
                        <th>Pace</th>
                        <th className='multi-line-header'>Tracker</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {games.map((game, index) => (
                        <tr key={game.id}>
                          <td>{global.isAdmin ? game.id :  index + 1}</td>
                          <td>{game.type}</td>
                          <td>
                            <span className='ctooltip'>
                              {game.members.length}
                              <span className='ctooltip-text'> {game.members.length ? game.members.map((m) => `${m.first_name} ${m.last_name}`).join(' , ') : "No Member"}</span>
                            </span>
                          </td>
                          <td>
                            <span className='ctooltip'>
                              {game.caddies.length}
                              <span className='ctooltip-text'> { game.caddies.length ? game.caddies.map((caddie) => `${caddie.first_name} ${caddie.last_name}`).join(' , ') : "No Caddie"}</span>
                            </span>
                          </td>
                          <td>{game.tee_time && strTo12Hours(game.tee_time)}</td>
                          <td>{game.actual_time}</td>
                          <td>{game.start_hole }</td>
                          <td>{game.last_hole_played}</td>
                          <td>{game.total_holes_played}</td>
                          <td>{game.time_through_last_hole}</td>
                          <td style={{color: game.pace_formatted[0] == '(' ? 'red' : 'green'}}>{game.pace_formatted}</td>
                          <td>{game.tracker_type} - { (game.tracker_type === 'airtag' ? airtags.find(device => device.tracking_id === game.tracker_id) : gpsTrackers.find(device => device.tracking_id === game.tracker_id))?.name}</td>
                          <td>
                            {statusText[game.status]}
                          </td>
                          <td>
                            <Button color="secondary" size="sm" onClick={() => openDetailModal(game)}>Detail</Button>
                            
                            { game.status !== 'completed' &&
                              <Button color="info" size="sm" onClick={() => openEditModal(game)}>Edit</Button>
                            }
                            <Button color="danger" size="sm" onClick={() => deleteGame(game.id)}>Delete</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={detailModal} toggle={() => setDetailModal(!detailModal)}>
            <ModalHeader toggle={() => setDetailModal(!detailModal)}>Details</ModalHeader>
            <ModalBody>
              <Table responsive style={{}}>
                <thead>
                  <th>Hole</th>
                  <th>Actual</th>
                  <th>Standard</th>
                  <th>Variance</th>
                  <th>Cumulative</th>
                </thead>
                <tbody>
                  {
                    selectedGame.box_entries_sorted?.filter((b) => b.box_number != 20 ).map((boxEntry) => 
                    <tr>
                      <td>{boxToHoleMappings[boxEntry.box_number]}</td>
                      <td>{boxEntry.timeTaken}</td>
                      <td>{boxEntry.standard}</td>
                      {
                        boxEntry.variance ?
                        <td style={{color: boxEntry.variance[0] == '(' ? 'red' : 'green'}}>{boxEntry.variance}</td>
                        :
                        <td></td>
                      }
                      {
                        boxEntry.cumulative ? 
                        <td style={{color: boxEntry.cumulative[0] == '(' ? 'red' : 'green'}}>{boxEntry.cumulative}</td>
                        :
                        <td></td>
                      }
                    </tr>)
                  }
                  <tr>
                    <td></td>
                    <td>{selectedGame.actualTotal}</td>
                    <td>{selectedGame.standardTotal}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>{editMode ? 'Edit Game' : 'Create Game'}</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label>Game Type</label>
                  <Input
                    type="select"
                    value={selectedGame.type}
                    onChange={(e) => setSelectedGame({ ...selectedGame, type: e.target.value })}
                  >
                    <option value={'9'}>9 Holes</option>
                    <option value={'18'}>18 Holes</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>Start Hole</label>
                  <Input
                    type="select"
                    value={selectedGame.start_box}
                    onChange={(e) => setSelectedGame({ ...selectedGame, start_box: e.target.value })}
                  >
                    {(selectedGame.type === '9' ? [1,10] : Object.keys(holeToBoxMappings)).map(hole => <option value={holeToBoxMappings[hole]}>{hole}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>Game Date</label>
                  <Input type="select" value={selectedGame.date} onChange={(e) => setSelectedGame({ ...selectedGame, date: e.target.value })}>
                    <option value={getLocalDate()}>{getLocalDate()}</option>
                    <option value={getLocalDate(1)}>{getLocalDate(1)}</option>
                    <option value={getLocalDate(2)}>{getLocalDate(2)}</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>Select Tee Time</label>
                  <Input
                    type="select"
                    value={selectedGame.tee_time}
                    onChange={(e) => setSelectedGame({ ...selectedGame, tee_time: e.target.value })}
                  >
                    <option value="">Please select</option>
                    {teeTimes.map((option) => <option value={option.value}>{option.label}</option>)}
                  </Input>
                </FormGroup>
                {selectedGame.tee_time === "custom" && (
                  <FormGroup>
                    <Label>Custom Tee Time</Label>
                    <Input
                      type="time"
                      value={selectedGame.customTeeTime}
                      required={true}
                      onChange={(e) => setSelectedGame({ ...selectedGame, customTeeTime: e.target.value })}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <label>Tracker Type</label>
                  <Input
                    type="select"
                    value={selectedGame.tracker_type}
                    onChange={(e) => setSelectedGame({ ...selectedGame, tracker_type: e.target.value })}
                  >
                    {[{value: 'airtag', label: 'Airtags'}, {value: 'gps', label: 'GPS'}].map((option => <option value={option.value}>{option.label}</option>))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>Tracker</label>
                  <Select
                    value={selectedGame.tracker_id}
                    options={selectedGame.tracker_type === 'airtag' ? 
                        airtags.map((tracker) => getTrackerOption(tracker, selectedGame.tracker_type))
                          :
                        gpsTrackers.map((tracker) => getTrackerOption(tracker, selectedGame.tracker_type))
                      }
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.data.color.backgroundColor,
                          color: 'white'
                        })
                      }}
                      onChange={(selected) => setSelectedGame({ ...selectedGame, tracker_id: selected })}
                  />
                </FormGroup>
                {
                  <FormGroup>
                  <label>Select Golfers</label>
                  <Select
                    isMulti
                    value={selectedGame.selectedMembers}
                    options={members.map(member => ({
                      value: member.id,
                      label: `${member.first_name} ${member.last_name} (${member.email || ''} - ${member.phone || ''})`
                    }))}
                    onChange={(selected) => setSelectedGame({ ...selectedGame, selectedMembers: selected })}
                  />
                  </FormGroup>
                }
                {
                  <FormGroup>
                    <label>Select Caddies</label>
                    <Select
                      isMulti
                      value={selectedGame.selectedCaddies}
                      options={caddies.map(caddie => ({
                        value: caddie.id,
                        label: `${caddie.first_name} ${caddie.last_name} (${caddie.email || ''} - ${caddie.phone || ''})`
                      }))}
                      onChange={(selected) => setSelectedGame({ ...selectedGame, selectedCaddies: selected })}
                    />
                  </FormGroup>
                }
                {
                  editMode && <FormGroup>
                  <label>Select Game Status</label>
                  <Input
                    type="select"
                    value={selectedGame.status}
                    onChange={(e) => setSelectedGame({ ...selectedGame, status: e.target.value })}
                  >
                    {Object.keys(statusText).map(key => <option value={key}>{statusText[key]}</option>)} 
                  </Input>
                </FormGroup>
                }
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={createOrUpdateGame}>{editMode ? 'Update Game' : 'Create Game'}</Button>{' '}
              <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
      }
    </>
  );
}

export default GameList;

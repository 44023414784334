import { useState, useEffect } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import CustomerHeader from "../../components/Headers/CustomerHeader";
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function BatteryLevel() {
  const [trackers, setTrackers] = useState([]);
  const clubId = session.get('clubInfo').clubId;

  useEffect(() => {
    fetchGpsTrackers();
    const interval = setInterval(fetchGpsTrackers, 60000);
    return () => { clearInterval(interval)}
  }, [clubId]);
  
  const fetchGpsTrackers = () => {
    // Fetch the list of GPS Trackers
    customerApi.getGpsTrackers({ club_id: clubId }).then((response) => {
      if (response.status === 200) {
        setTrackers(response.data.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        helper.showToast("Error", "Failed to load GPS Trackers", 3);
      }
    });
  }

  const getBatteryClass = (level) => {
    if (level <= 30) return "bg-danger text-white";
    if (level <= 60) return "bg-warning text-white";
    return "bg-success text-white";
  };

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7 mb-8" fluid>
        <Card className="bg-default shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="text-white mb-0 text-center">GPS Trackers</h2>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-dark table-flush" responsive>
            <thead className="thead-dark">
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Battery Level</th>
              </tr>
            </thead>
            <tbody>
              {trackers.map((tracker, index) => (
                <tr key={index} className={getBatteryClass(tracker.battery_level)}>
                  <td>{index + 1}</td>
                  <td>{tracker.name}</td>
                  <td>{tracker.battery_level}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

export default BatteryLevel;
